<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table
        class="uk-table uk-table-divider uk-table-middle"
        aria-describedby="dashboard-access-table"
      >
        <thead class="thead-table-varion">
          <tr>
            <th class="uk-table-expand">
              Menu
            </th>
            <th>View</th>
            <th>Edit</th>
            <th>Add</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(val, index) in data"
            :key="index"
          >
            <td>{{ val.name }}</td>        
            <td v-if="$route.name == 'RoleDetail'">
              <span v-if="val.view">
                <img
                  :src="`${images}/icon/ic_checklist_table.svg`"
                  alt="checked"
                >
              </span>
              <span v-else>
                <img
                  :src="`${images}/icon/ic_close_table.svg`"
                  alt="close"
                >
              </span>
            </td>

            <td v-else>
              <input
                class="uk-checkbox uk-margin-small-left"
                :checked="val.view"
                type="checkbox"
                name="role_management"
                @change="handleCheckedCms($event, index, 'view')"
              >
            </td>

            <td v-if="$route.name == 'RoleDetail'">
              <span v-if="val.edit">
                <img
                  :src="`${images}/icon/ic_checklist_table.svg`"
                  alt="checked"
                >
              </span>
              <span v-else>
                <img
                  :src="`${images}/icon/ic_close_table.svg`"
                  alt="close"
                >
              </span>
            </td>

            <td v-else>
              <input
                class="uk-checkbox uk-margin-small-left"
                :checked="val.edit"
                type="checkbox"
                name="role_management"
                :disabled="!val.view"
                @change="handleCheckedCms($event, index, 'edit')"
              >
            </td>

            <td v-if="$route.name == 'RoleDetail'">
              <span v-if="val.add">
                <img
                  :src="`${images}/icon/ic_checklist_table.svg`"
                  alt="checked"
                >
              </span>
              <span v-else>
                <img
                  :src="`${images}/icon/ic_close_table.svg`"
                  alt="close"
                >
              </span>
            </td>

            <td v-else>
              <input
                class="uk-checkbox uk-margin-small-left"
                :checked="val.add"
                type="checkbox"
                name="role_management"
                :disabled="!val.view"
                @change="handleCheckedCms($event, index, 'add')"
              >
            </td>

            <td v-if="$route.name == 'RoleDetail'">
              <span v-if="val.delete">
                <img
                  :src="`${images}/icon/ic_checklist_table.svg`"
                  alt="checked"
                >
              </span>
              <span v-else>
                <img
                  :src="`${images}/icon/ic_close_table.svg`"
                  alt="close"
                >
              </span>
            </td>

            <td v-else>
              <input
                class="uk-checkbox uk-margin-small-left"
                :checked="val.delete"
                type="checkbox"
                name="role_management"
                :disabled="!val.view"
                @change="handleCheckedCms($event, index, 'delete')"
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      required: true,
      type: String
    },
    data: {
      required: true,
      type: Array
    },
    handleCheckedCms:{
      required: true,
      type: Function
    }
  }
}
</script>

<style scoped>
  .uk-checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #C4C8D4;
    border-radius: 4px;
  }
  .uk-checkbox:checked, .uk-checkbox:indeterminate {
    background-color: #2FAC4A;
    border-color: transparent;
  }
  .custom-margin {
    border-bottom: 1px solid #F6F7FA;
    border-bottom-width: 15px;
  }
</style>
