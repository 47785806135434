<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table
        class="uk-table uk-table-divider uk-table-middle"
        aria-describedby="app-access-table"
      >
        <thead class="thead-table-varion">
          <tr>
            <th class="uk-table-expand">
              Menu Access
            </th>
            <th class="uk-width-small" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(val, index) in data"
            :key="index"
          >
            <td>{{ val.name }}</td>
        
            <td v-if="$route.name == 'RoleDetail'">
              <span v-if="val.view">
                <img
                  :src="`${images}/icon/ic_checklist_table.svg`"
                  alt="checked"
                >
              </span>
              <span v-else>
                <img
                  :src="`${images}/icon/ic_close_table.svg`"
                  alt="close"
                >
              </span>
            </td>

            <td v-else>
              <input
                class="switcher uk-margin-small-left"
                :checked="val.view"
                type="checkbox"
                name="app_management"
                @change="handleCheckedApp($event, index, 'view')"
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      required: true,
      type: String
    },
    data: {
      required: true,
      type: Array
    },
    handleCheckedApp:{
      required: true,
      type: Function
    }
  }  
}
</script>
