<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-top base-card">
      <div class="uk-form-stacked">
        <h4 class="uk-heading-line">
          Role Info
        </h4>
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Role Name
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formRole.name"
                    v-validate="'required'"
                    name="role_name"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Role Name"
                    :class="{'uk-form-danger': errors.has('role_name')}"
                  >
                </div>
                <span
                  v-show="errors.has('role_name')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('role_name') }}</span>
              </div>
            </div>
          </div>
          
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <div class="uk-form-controls uk-position-relative withlabel">
                  <label class="uk-form-label">Assigned at</label>
                  <select
                    v-model="formRole.assigned_at"
                    name="assigned_at"
                    class="uk-width-expand custom-select"
                    placeholder="Select Facility"
                  >
                    <option
                      value=""
                      hidden
                    >
                      Select Facility
                    </option>
                    <option
                      v-for="(item, key) in listAssignAt"
                      :key="key"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <img
                    :src="`${images}/icon/ic_chevron_down.svg`"
                    alt="ic_chevron"
                  >
                </div>
                <span
                  v-show="errors.has('assigned_at')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('assigned_at') }}</span>
              </div>
            </div>
          </div>

          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Status
                </label>
                <div class="uk-form-controls">
                  <label class="switch">
                    <input
                      v-if="formRole.status"
                      class="switcher"
                      name="status"
                      type="checkbox"
                      checked
                      @change="handleStatus"
                    >
                    <input
                      v-else
                      class="switcher"
                      name="status"
                      type="checkbox"
                      @change="handleStatus"
                    >
                    <span class="slider round" />
                  </label>
                  <span :class="formRole.status == 1 ? 'label-switcher active ' : 'label-switcher'">{{ formRole.status == 1 ? 'Active' : 'Inactive' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <h4 class="uk-heading-line">
        Access Management
      </h4>

      <create-edit-detail-role />
  
      <div class="uk-flex uk-flex-right uk-margin-medium-top">
        <button
          class="uk-button soft-green uk-margin-right"
          @click="showCancelConfirmModal"
        >
          Cancel
        </button>
        <button
          class="uk-button green"
          :class="isCanAccessUser('edit', 'ROLE') ? '' : 'disabled'"
          :disabled="!isCanAccessUser('edit', 'ROLE')"
          @click="showSaveConfirmModal"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CreateEditDetailRole from '@/components/globals/table/role/CreateEditDetail'
import { isCanAccess } from '@/utils/auth'

export default { 
  components: {
    CreateEditDetailRole
  },
  props: {
    images:{
      required: true,
      type: String
    },
    listAssignAt: {
      required: true,
      type: Array
    },
    setToggleModalAddEditDiscard: {
      required: true,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    },
    setDataModalAddEdit: {
      required: true,
      type: Function
    }
  },
  
  data() {
    return{
      formRole: {
        name: '',
        assigned_at: '',
        status: 1,
        role_management: []
      }
    }
  },
  computed: {
    ...mapGetters({
      detail_role: 'role/detail_role',
      getDataRole: 'role/get_data_modal_add_edit'
    })
  },
  watch:{
    async detail_role(val) {
      if(val) {
        this.formRole = {
          name: val.name || '',
          assigned_at: val.assigned_at || '',
          status: val.status,
          role_management: []
        }
      }
    }
  },
  methods: {
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    handleStatus(event) {
      this.status = event.target.checked ? 1 : 0
    },
    showCancelConfirmModal() {
      this.setToggleModalAddEditDiscard({
        targetModals: true,
        title: 'Cancel Confirmation', 
        description: 'All data will be lost if you leave. Are you sure want to leave?',
        customTitleBtnOk: 'Yes',
        customTitleBtnCancel: 'No',
        type: 'discard',
        route: 'Role',
        loadingTable: false,
        loadingPopUp: false
      })
    },
    showSaveConfirmModal() {
      this.formRole.role_management = this.getDataRole
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.setDataModalAddEdit(this.formRole)
          this.setToggleModalAddEditDiscard({
            targetModals: true,
            title: 'Save Confirmation', 
            description: 'Are you sure want to save this data?',
            customTitleBtnOk: 'Save',
            customTitleBtnCancel: 'Cancel',
            type: 'save',
            loadingTable: false,
            loadingPopUp: false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .uk-tab > * > a{
    font-size: 16px;
    font-weight: 700;
  }
</style>
