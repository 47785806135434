<template>
  <div>
    <ul
      uk-tab
    >
      <li class="uk-active">
        <a href="#">Dashboard Access</a>
      </li>
      <li>
        <a href="#">App Access</a>
      </li>
    </ul>

    <ul class="uk-switcher uk-margin">
      <li class="uk-active">
        <table-dashboard-access
          :images="images"
          :data="newTempDashboard"
          :handle-checked-cms="handleCheckedCms"
        />
      </li>
      <li>
        <table-app-access 
          :images="images"
          :data="newTempApp"
          :handle-checked-app="handleCheckedApp"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from "@/utils/constant"
import TableDashboardAccess from '@/components/globals/table/role/dashboard-access'
import TableAppAccess from '@/components/globals/table/role/app-access'

export default {
  components: {
    TableDashboardAccess,
    TableAppAccess
  },
  data() {
    return{
      images: PREFIX_IMAGE,
      menuCms: [],
      menuApp: [],
      newTempDashboard: [],
      newTempApp: [],
      tempObjDashboard: [
        {
          view: false,
          add: false,
          edit: false,
          delete: false,
          type: 'cms'
        }
      ],
      tempObjApp: [
        {
          view: false,
          add: false,
          edit: false,
          delete: false,
          type: 'app'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      detail_role: 'role/detail_role',
      listMenuRole: 'role/list_role_menu',
      getDataModalAddEdit: 'role/get_data_modal_add_edit'
    })
  },
  watch: {
    async detail_role(val) {
      if (this.$route.params.id) {
        this.newTempDashboard = { ...this.newTempDashboard, ...val.role_management.filter(role =>  role.type == 'cms').map(({ id, ...rest }) => ({ menu_id: id, ...rest }))}
        this.newTempApp = { ...this.newTempApp, ...val.role_management.filter(role =>  role.type == 'app').map(({ id, ...rest }) => ({ menu_id: id, ...rest }))}

        const combinedObject = [...Object.values(this.newTempDashboard),...Object.values(this.newTempApp)]

        this.setDataModalAddEdit(combinedObject)
      }
    },
    listMenuRole(){
      let menuDashboard = this.listMenuRole.filter(item => {
        if(item.type == 'cms') {
          return item
        }
      })
      let menuApps = this.listMenuRole.filter(item => {
        if(item.type == 'app') {
          return item
        }
      })
      this.menuCms = menuDashboard
      this.menuApp = menuApps
    }
  },
  async mounted() {
    await this.actionGetListRoleMenu({ is_all: true })
    this.menuCms.forEach(item => {
      this.tempObjDashboard.forEach(item2 => {
        this.newTempDashboard.push({
          menu_id: item.id,
          name: item.name,
          ...item2
        })
      })
    }),

    this.menuApp.forEach(item => {
      this.tempObjApp.forEach(item2 => {
        this.newTempApp.push({
          menu_id: item.id,
          name: item.name,
          ...item2
        })
      })
    })
    const cms = this.newTempDashboard
    const app = this.newTempApp
    this.setDataModalAddEdit({...this.getDataModalAddEdit, cms})
    this.setDataModalAddEdit({...this.getDataModalAddEdit, app})
  },
  methods: {
    ...mapActions({
      actionGetListRoleMenu: 'role/getListMenuRole'
    }),
    ...mapMutations({
      setDataModalAddEdit: 'role/SET_DATA_MODAL_ADD_EDIT'
    }),
    mergeAndModifyArrays(array1, array2, finderId){
      const mergedArray = array1.map(item1 => {
        const matchingItem = array2.find(item2 => item2[finderId] === item1[finderId])
        if (matchingItem) {
          return { ...item1, ...matchingItem }
        }
        return item1
      })

      return mergedArray
    },
    handleCheckedCms(event, i, type) {
      this.newTempDashboard[i][type] = event.target.checked
      if(!this.newTempDashboard[i].view){
        this.newTempDashboard[i].add = false
        this.newTempDashboard[i].edit = false
        this.newTempDashboard[i].delete = false
      }
      const cms = this.newTempDashboard
      
      if (this.$route.name == 'RoleEdit') {
        const filterData = this.mergeAndModifyArrays(this.getDataModalAddEdit, cms, 'menu_id')
        this.setDataModalAddEdit(filterData)
      } else{
        this.setDataModalAddEdit({...this.getDataModalAddEdit, cms})
      }

    },
    handleCheckedApp(event, i, type) {
      let app
      this.newTempApp[i][type] = event.target.checked
      
      if (Array.isArray(this.newTempApp)) {
        app = this.newTempApp.map(item => {
          if (item.view == true) {
            item.view = true
            item.add = true
            item.edit = true
            item.delete = true
          } else {
            item.add = false
            item.edit = false
            item.delete = false
          }
          return item
        })
      } else {
        const values = Object.values(this.newTempApp)
        app = values.map(item => {
          if (item.view == true) {
            item.view = true
            item.add = true
            item.edit = true
            item.delete = true
          } else {
            item.add = false
            item.edit = false
            item.delete = false
          }
          return item
        })
      }

      if (this.$route.name == 'RoleEdit') {
        const filterData = this.mergeAndModifyArrays(this.getDataModalAddEdit, app, 'menu_id')
        this.setDataModalAddEdit(filterData)
      } else {
        this.setDataModalAddEdit({ ...this.getDataModalAddEdit, app })
      }
    }
  }
}
</script>

<style scoped>
.uk-tab > * > a {
  font-size: 16px;
}
</style>
